@import 'reset';
@import 'variables';
@import 'pico';

html,
body {
    padding: 0;
    margin: 0;
    // font-family: $font-primary;
    min-height: 100vh;
}

strong {
    font-weight: bold;
}

[data-tooltip].no-tooltip-underline {
    border-bottom: none;
}

nav,
nav.container-fluid {
    padding: 50px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

nav {
    svg {
        opacity: 0;
        animation-play-state: paused;
        transition: opacity 0.25s ease-in-out;
        &.loading {
            opacity: 1;
            animation-play-state: running;
            animation-name: spin;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }
}

dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5em;
    dl {
        width: 100%;
    }
    dt {
        font-weight: bold;
        width: 200px;
    }
    dd {
        flex: 1;
    }

    hr {
        width: 100%;
        margin: 1em 0;
    }
}

article {
    header {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
        }
    }
}

@import 'login';
