.login_page {
    main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - 7rem);
        padding: 1rem 0;
    }

    article {
        padding: 0;
        overflow: hidden;
    }

    article div {
        padding: 1rem;
    }

    @media (min-width: 576px) {
        main {
            padding: 1.25rem 0;
        }

        article div {
            padding: 1.25rem;
        }
    }

    @media (min-width: 768px) {
        main {
            padding: 1.5rem 0;
        }

        article div {
            padding: 1.5rem;
        }
    }

    @media (min-width: 992px) {
        main {
            padding: 1.75rem 0;
        }

        article div {
            padding: 1.75rem;
        }
    }

    @media (min-width: 1200px) {
        main {
            padding: 2rem 0;
        }

        article div {
            padding: 2rem;
        }
    }

    /* Hero Image */
    article div:nth-of-type(2) {
        display: none;
        background-color: #374956;
        background-image: url('https://source.unsplash.com/aS4Duj2j7r4/1000');
        background-position: center;
        background-size: cover;
    }

    @media (min-width: 992px) {
        .grid > div:nth-of-type(2) {
            display: block;
        }
    }
}
